import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// icon
import { MdDashboard, MdPerson } from 'react-icons/md';
import { IoMdLogIn } from 'react-icons/io';

import { signOut } from '../../store/modules/auth/actions';

// logo
import logo from '../../assets/images/logo512_white.png';

import { Container, Content, Profile } from './styles';

export default function Header() {
  const dispatch = useDispatch();
  const { profile } = useSelector(state => state.user);

  function handleSignOut() {
    dispatch(signOut());
  }

  return (
    <Container>
      <Content>
        <nav>
          <img src={logo} alt="nomind" title="nomind" style={{ height: 30 }} />
          <Link to="/dashboard">
            <MdDashboard className="mr-05" /> DASHBOARD
          </Link>
        </nav>
        <aside>
          <Profile>
            <div>
              <strong>{profile.name}</strong>
              <Link to="/profile">
                <MdPerson className="mr-05" /> Meu perfil
              </Link>
            </div>

            <button type="button" onClick={handleSignOut}>
              <IoMdLogIn /> <span>SAIR</span>
            </button>
          </Profile>
        </aside>
      </Content>
    </Container>
  );
}

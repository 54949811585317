import styled from 'styled-components';
import { darken, lighten } from 'polished';

import { Link } from 'react-router-dom';

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  max-width: 900px;
  margin: 60px auto;
  padding: 0 20px;
`;

export const Title = styled.strong`
  margin: 0 0 20px;
  color: #6c63ff;
  font-size: 22px;
  font-weight: 700;
  display: flex;
  align-items: center;
`;

export const CardMain = styled.div`
  width: 100%;
  background: #fff;
  padding: 20px;
  border-radius: 4px;
  margin-bottom: 20px;

  > header {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    > div > hr {
      width: 100%;
      height: 1px;
      margin: 10px auto;
      overflow: hidden;
      background-color: #dee2e6;
      border: 0;
    }
    > div > strong {
      font-size: 16px;
      color: #6c63ff;
      text-align: center;
    }
    > div > span {
      font-size: 14px;
      color: grey;
      text-align: center;
      margin-bottom: 5px;
      font-weight: 700;
      margin-top: 10px;
    }

    > div > h3 {
      font-size: 18px;
      color: #6c63ff;
      text-align: center;
      margin-bottom: 5px;
    }
    > div > p.description {
      font-size: 14px;
      color: grey;
      margin-bottom: 10px;
    }
    > div > p.nickname {
      font-size: 14px;
      color: grey;
      margin-bottom: 10px;
      text-align: center;
    }
  }
`;

export const ButtonAction = styled(Link)`
  padding: 10px;
  background: #6c63ff;
  border: 0;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AvatarContainer = styled.div`
  width: 150px;
  height: 150px;
  overflow: hidden;
  border-radius: 100px;
  border: 2px solid #dee2e6;
  background-color: #dee2e6;
  display: flex;
  justify-content: center;
  align-items: center;

  > img {
    width: 100%;
    height: 100%;
  }
`;

export const ButtonNoAction = styled.button`
  padding: 10px;
  background: ${props => props.bgColor};
  border: 0;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import { toast } from 'react-toastify';

// ReactLoading
import ReactLoading from 'react-loading';

// icons
import { MdAccountBox, MdPeopleOutline, MdGroupAdd } from 'react-icons/md';
import { IoIosCreate, IoIosTrash } from 'react-icons/io';

// api
import api from '../../services/api';

// styles
import {
  Container,
  HeaderTab,
  ButtonActionTabs,
  ImageAvatarPartners,
  ButtonAction,
  ButtonActionCreate,
} from './styles';

export default function Services() {
  const [loading, setLoading] = useState(true);
  const [services, setServices] = useState(null);

  async function getServices() {
    try {
      const responseServices = await api.get('services');
      setServices(responseServices.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error('Erro ao listar serviços');
    }
  }

  //console.tron.log(services);

  // async function handleDelete(id) {
  //   try {
  //     setLoading(true);
  //     const responseServices = await api.delete(`services/delete/${id}`);
  //     toast.success(`${responseServices.data.message}`);
  //     window.location.reload(true);
  //   } catch (error) {
  //     setLoading(false);
  //     toast.error('Erro ao deletar serviço');
  //   }
  // }

  useEffect(() => {
    getServices();
  }, []);

  const columns = [
    {
      name: 'id',
      label: 'ID',
    },
    {
      name: 'url_photo',
      label: 'Imagem',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <ImageAvatarPartners>
            {value.substr(value.length - 4) === 'null' ? (
              <small>Serviço sem imagem</small>
            ) : (
              <img src={value} alt="Serviços" />
            )}
          </ImageAvatarPartners>
        ),
      },
    },
    {
      name: 'name',
      label: 'Nome',
    },

    {
      name: 'status',
      label: 'Status',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            {value ? (
              <span style={{ color: '#008000', fontWeight: '700' }}>
                ATIVADO
              </span>
            ) : (
              <span style={{ color: '#C70039', fontWeight: '700' }}>
                DESATIVADO
              </span>
            )}
          </div>
        ),
      },
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            <ButtonAction to={`/service/${value}`} className="mb-1">
              <IoIosCreate style={{ marginRight: 10 }} /> Editar
            </ButtonAction>
            {/* <button
              type="button"
              className="btn btn-danger w-100"
              data-toggle="modal"
              data-target={`#delete_${value}`}
            >
              <IoIosTrash style={{ marginRight: 10 }} /> Deletar
            </button>

            <div
              className="modal fade"
              id={`delete_${value}`}
              tabIndex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Tem certeza que deseja deletar este parceiro?
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Não
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => handleDelete(value)}
                    >
                      Sim, deletar parceiro
                    </button>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        ),
      },
    },
  ];

  const options = {
    filter: false,
    searchable: true,
    selectableRows: false,
    rowsPerPage: 15,
    elevation: 2,
    textLabels: {
      body: {
        noMatch: 'Nenhum serviço foi registrado',
        toolTip: 'Organizar',
        columnHeaderTooltip: column => `Organizar por ${column.label}`,
      },
      pagination: {
        next: 'Próxima página',
        previous: 'Página anterior',
        rowsPerPage: 'Registros por página:',
        displayRows: 'de',
      },
      toolbar: {
        search: 'Buscar',
        downloadCsv: 'Download CSV',
        print: 'Imprimir',
        viewColumns: 'Visualizar colunas',
        filterTable: 'Filtrar tabela',
      },
      filter: {
        all: 'Todos',
        title: 'FILTROS',
        reset: 'RESETAR',
      },
      viewColumns: {
        title: 'Mostrar colunas na tabela',
        titleAria: 'Mostrar/Esconder colunas',
      },
      selectedRows: {
        text: 'linha(s) selecionadas',
        delete: 'Deletar',
        deleteAria: 'Deletar registros selecionados',
      },
    },
  };
  return (
    <Container>
      <HeaderTab>
        <ButtonActionTabs className="mr-2" to="/dashboard">
          <MdAccountBox className="mr-05" /> Lista de profissionais
        </ButtonActionTabs>
        <ButtonActionTabs to="/partners" className="mr-2">
          <MdPeopleOutline className="mr-05" /> Lista de parceiros
        </ButtonActionTabs>
        <ButtonActionTabs active>
          <MdPeopleOutline className="mr-05" /> Lista de serviços
        </ButtonActionTabs>
      </HeaderTab>

      <ButtonActionCreate to="/services/create">
        <MdGroupAdd className="mr-05" /> Adicionar novo serviço
      </ButtonActionCreate>

      {/* <Title>
        <MdAccountBox className="mr-05" /> Lista de provedores
      </Title> */}

      {loading ? (
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ReactLoading type="bubbles" color="#6c63ff" height={40} width={40} />
        </div>
      ) : (
        services !== null && (
          <MUIDataTable
            title="Parceiros"
            data={services}
            columns={columns}
            options={options}
          />
        )
      )}
    </Container>
  );
}

import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';

// ReactLoading
import ReactLoading from 'react-loading';

// Icons
import { IoIosLogIn } from 'react-icons/io';

// Logo
import logo from '../../assets/images/logo512_white.png';

import api from '../../services/api';

// Yup validation schema
const schema = Yup.object().shape({
  password: Yup.string()
    .min(6, 'Digite uma senha com no mínimo 6 caracteres')
    .required('Digite uma senha'),
});

export default function ResetPass({ match }) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const { token } = match.params;

  async function handleSubmit({ password }) {
    setLoading(true);

    if (token === null) {
      return toast.error('Token inválido');
    }
    try {
      const response = await api.put('/user/update/password', {
        token,
        password,
      });
      setLoading(false);
      setSuccess(true);
      toast.success('Senha atualizada com sucesso!');
    } catch ({ error, response }) {
      setLoading(false);
      setSuccess(false);
      return toast.error(`${response.data.error}`);
    }
  }

  return (
    <>
      <img src={logo} alt="Nomind" title="Nomind" style={{ width: '40%' }} />

      {success ? (
        <div className="container">
          <h4 className="mt-5 mb-5 text-white font-weight-bold">
            Volte ao app para realizar o login novamente
          </h4>
        </div>
      ) : (
        <>
          <h3 className="m-2 text-white">Digite sua nova senha</h3>
          <Form schema={schema} onSubmit={handleSubmit}>
            <Input name="password" type="password" placeholder="Senha" />

            <button type="submit">
              {loading ? (
                <ReactLoading
                  type="bubbles"
                  color="#fff"
                  height={40}
                  width={40}
                />
              ) : (
                <>
                  <IoIosLogIn size={20} className="mr-1" /> Entrar
                </>
              )}
            </button>
          </Form>
        </>
      )}
    </>
  );
}

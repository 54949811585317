import styled from 'styled-components';
import { darken, lighten } from 'polished';

import { Link } from 'react-router-dom';

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  max-width: 900px;
  margin: 60px auto;
  padding: 0 20px;

  button.profileUpdate {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 10px auto;
    background: #6c63ff;
    border: 0;
    border-radius: 4px;
    height: 44px;
    color: #fff;
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
    transition: background 0.2s;

    &:hover {
      background: ${darken(0.08, '#6c63ff')};
    }
  }
`;

export const HeaderTab = styled.header`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: 1px;
  border-bottom-color: grey;
`;

export const ButtonActionTabs = styled(Link)`
  padding: 10px;
  background: #6c63ff;
  opacity: ${props => (props.active ? '1' : '0.5')};
  border: 0;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.strong`
  margin: 0 0 20px;
  color: #6c63ff;
  font-size: 22px;
  font-weight: 700;
  display: flex;
  align-items: center;
`;

export const CardMain = styled.div`
  width: 100%;
  background: #fff;
  padding: 20px;
  border-radius: 4px;
`;

export const ButtonAction = styled(Link)`
  padding: 10px;
  background: ${props => (props.delete ? '#C70039' : '#FFC300')};
  border: 0;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
`;

export const ButtonNoAction = styled.button`
  width: 100%;
  padding: 10px;
  background: ${props => (props.delete ? '#C70039' : '#FFC300')};
  border: 0;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
`;

export const ImageAvatarPartners = styled.div`
  width: 100px;
  overflow: hidden;
  border: 2px solid #fff;
  padding: 5px;
  background-color: #dee2e6;
  border-radius: 4px;
`;

export const FormGroup = styled.div`
  width: 100%;
  padding: 20px 20px 10px;
  background: ${lighten(0.04, '#DEE2E6')};
  display: flex;
  flex-direction: column;
  margin: 10px auto 30px auto;
  border-radius: 4px;
  border: 1px solid ${darken(0.02, '#DEE2E6')};

  input {
    background: #fff;
    border: 0;
    border-radius: 4px;
    height: 44px;
    padding: 0 15px;
    color: #6c63ff;
    font-weight: 700;
    margin: 0 0 15px 0;

    &::placeholder {
      color: #c4cad3;
    }
  }

  span {
    margin: -10px 0 20px 0;
    background: #f64c75;
    color: #fff;
    padding: 10px;
    font-size: 12px;
  }
`;

export const ContainerImage = styled.div`
  align-self: center;
  margin-bottom: 30px;

  label {
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }

    img {
      width: 120px;
      height: 120px;
      border-radius: 60px;
      border: 3px solid #fff;
      background: ${darken(0.04, '#FFF')};
    }

    input {
      display: none;
    }
  }
`;

export const ButtonActionCreate = styled(Link)`
  padding: 10px;
  background: #008000;
  border: 0;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin: 20px auto;
`;

/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Form, Input, Check } from '@rocketseat/unform';

// ReactLoading
import ReactLoading from 'react-loading';

// icons
import { MdAccountBox, MdRefresh } from 'react-icons/md';

// api
import api from '../../services/api';

import history from '../../services/history';

// styles
import {
  Container,
  Title,
  CardMain,
  FormGroup,
  ContainerImage,
} from './styles';

export default function ServiceEdit({ match }) {
  const [loading, setLoading] = useState(true);
  const [loadingImage, setLoadingImage] = useState(false);
  const [service, setService] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  const { id } = match.params;

  async function getService() {
    try {
      const responseService = await api.get(`services/show/${id}`);
      setService(responseService.data);
      setPreviewImage(responseService.data.url_photo);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error('Erro ao listar serviço');
    }
  }

  // upload new image
  async function handleChange(e) {
    setLoadingImage(true);
    const file = e.target.files[0];
    const data = new FormData();
    data.append('file', file);
    try {
      const responseImage = await api.put(`services/update/foto/${id}`, data);
      setPreviewImage(responseImage.data.url_photo);
      setLoadingImage(false);
    } catch (error) {
      setLoadingImage(false);
      toast.error('Erro ao atualizar imagem de serviço');
    }
  }

  // update services values
  async function handleUpdate(data) {
    if (data.name === '') {
      return toast.error('O campo Nome é obrigatório');
    }
    try {
      setLoading(true);
      const responseUpdate = await api.put(`services/update/${id}`, {
        name: data.name,
        description: data.description,
        status: data.status === false ? 0 : 1,
      });
      setService(responseUpdate.data);
      setLoading(false);
      toast.success('Dados atualizados com sucesso!');
      return history.push('/services');
    } catch (error) {
      setLoading(false);
      return toast.error('Erro ao atualizar serviço');
    }
  }

  useEffect(() => {
    getService();
  }, [getService]);

  const options = [
    { id: 0, title: 'Desativado' },
    { id: 1, title: 'Ativado' },
  ];

  return (
    <Container>
      {/* <HeaderTab>
        <ButtonActionTabs className="mr-2" to="/dashboard">
          <MdAccountBox className="mr-05" /> Lista de provedores
        </ButtonActionTabs>
        <ButtonActionTabs active>
          <MdPeopleOutline className="mr-05" /> Lista de parceiros
        </ButtonActionTabs>
      </HeaderTab> */}
      <Title>
        <MdAccountBox className="mr-05" /> {service !== null && service.name}
      </Title>

      {loading ? (
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ReactLoading type="bubbles" color="#6c63ff" height={40} width={40} />
        </div>
      ) : (
        service !== null && (
          <CardMain>
            <Form initialData={service} onSubmit={handleUpdate}>
              <FormGroup>
                <ContainerImage>
                  {loadingImage ? (
                    <ReactLoading
                      type="bubbles"
                      color="#6c63ff"
                      height={40}
                      width={40}
                    />
                  ) : (
                    <label htmlFor="logo">
                      <img src={previewImage} alt="Logo" />
                      <input
                        type="file"
                        accept="image/*"
                        id="logo"
                        onChange={handleChange}
                      />
                    </label>
                  )}
                </ContainerImage>
              </FormGroup>
              <FormGroup status={service.status}>
                <Input name="name" placeholder="Nome do perceiro" required />
                <Input name="description" placeholder="Descrição" />
                <div className="checkInput">
                  <Check className="checkInput" name="status" /> <p>Ativado</p>
                </div>
              </FormGroup>

              <button className="profileUpdate" type="submit">
                <MdRefresh size={20} className="mr-1" /> Atualizar valores
              </button>
            </Form>
          </CardMain>
        )
      )}
    </Container>
  );
}

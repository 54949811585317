import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

// Pages
import SignIn from '../pages/SignIn';
import ResetPass from '../pages/ResetPass';
// import SignUp from '../pages/SignUp';
import Dashboard from '../pages/Dashboard';
import ProviderDetails from '../pages/ProvidersDetails';
import Profile from '../pages/Profile';

import Partners from '../pages/Partners';
import PartnerEdit from '../pages/Partners/edit';
import PartnerCreate from '../pages/Partners/create';

import Services from '../pages/Services';
import ServiceEdit from '../pages/Services/edit';
import ServiceCreate from '../pages/Services/create';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/reset/password/:token" exact component={ResetPass} />
      {/* <Route path="/register" exact component={SignUp} /> */}

      <Route path="/dashboard" component={Dashboard} exact isPrivate />
      <Route path="/provider/:id" component={ProviderDetails} exact isPrivate />

      <Route path="/partners" component={Partners} exact isPrivate />
      <Route path="/partner/:id" component={PartnerEdit} exact isPrivate />
      <Route
        path="/partners/create"
        component={PartnerCreate}
        exact
        isPrivate
      />

      <Route path="/services" component={Services} exact isPrivate />
      <Route path="/service/:id" component={ServiceEdit} exact isPrivate />
      <Route
        path="/services/create"
        component={ServiceCreate}
        exact
        isPrivate
      />

      <Route path="/profile" component={Profile} exact isPrivate />

      <Route path="/" component={() => <h1>Error 404</h1>} />
    </Switch>
  );
}

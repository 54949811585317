import styled from 'styled-components';
import { darken, lighten } from 'polished';

import { Link } from 'react-router-dom';

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  max-width: 900px;
  margin: 60px auto;
  padding: 0 20px;
`;

export const HeaderTab = styled.header`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: 1px;
  border-bottom-color: grey;
`;

export const ButtonActionTabs = styled(Link)`
  padding: 10px;
  background: #6c63ff;
  opacity: ${props => (props.active ? '1' : '0.5')};
  border: 0;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.strong`
  margin: 0 0 20px;
  color: #6c63ff;
  font-size: 22px;
  font-weight: 700;
  display: flex;
  align-items: center;
`;

export const CardMain = styled.div`
  width: 100%;
  background: #fff;
  padding: 20px;
  border-radius: 4px;
`;

export const ButtonAction = styled(Link)`
  padding: 10px;
  background: #6c63ff;
  border: 0;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

import React from 'react';

import { Container, Inside } from './styles';

export default function Footer() {
  return (
    <Container>
      <Inside>
        <p>Desenvolvido por noMind e Agência ALX</p>
      </Inside>
    </Container>
  );
}

import { createGlobalStyle } from 'styled-components';

import 'react-toastify/dist/ReactToastify.css';
import 'react-perfect-scrollbar/dist/css/styles.css';

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Raleway:400,700&display=swap');

  *{
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  *:focus{
    outline: 0;
  }

  html, body, #root{
    height: 100%;
  }

  body {
    -webkit-font-smoothing: antialiased;
  }

  body, input, button {
    font: 14px 'Raleway', sans-serif;
  }


  a, a:link, a:hover{
    text-decoration: none;
  }
 a:hover{
    color: #fff;
  }

  ul {
    list-style: none;
  }

  button {
    cursor: pointer;
    outline: 0;
    border: 0;
  }

  .mr-05{
    margin-right: 5px;
  }
  .mr-1{
    margin-right: 10px;
  }
  .mr-2{
    margin-right: 20px;
  }
  .mb-1{
    margin-bottom: 10px;
  }

`;

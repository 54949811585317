import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import MUIDataTable from 'mui-datatables';
import { toast } from 'react-toastify';

// ReactLoading
import ReactLoading from 'react-loading';

// icons
import { MdAccountBox, MdPeopleOutline } from 'react-icons/md';
import { IoIosPerson, IoMdColorFilter } from 'react-icons/io';

// api
import api from '../../services/api';

import { signOut } from '../../store/modules/auth/actions';

// styles
import { Container, HeaderTab, ButtonActionTabs, ButtonAction } from './styles';

export default function Dashboard() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [providers, setProviders] = useState([]);
  const [providersFilter, setProvidersFilter] = useState([]);
  const [nameFilter, setNameFilter] = useState('Nenhum');
  const [showFilter, setShowFilter] = useState(false);

  function handleFilter(filter) {
    setProvidersFilter(
      providers.filter(provider => provider.status === filter)
    );
    if (filter === 1) {
      setNameFilter('Pendentes');
    } else if (filter === 2) {
      setNameFilter('Aprovados');
    } else {
      setNameFilter('Reprovados');
    }

    if (filter === 3) {
      setShowFilter(false);
      setNameFilter('Nenhum');
    } else {
      setShowFilter(true);
    }
  }

  useEffect(() => {
    async function getProviders() {
      try {
        const responseProviders = await api.get('admin/providers/list');
        setProviders(responseProviders.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        dispatch(signOut());
        // toast.error('Erro ao listar provedores');
      }
    }
    getProviders();
  }, [dispatch]);

  const columns = [
    {
      name: 'id',
      label: 'ID',
    },
    {
      name: 'name',
      label: 'Nome',
    },
    {
      name: 'email',
      label: 'E-mail',
    },
    {
      name: 'phone',
      label: 'Telefone',
    },
    {
      name: 'cpf',
      label: 'CPF',
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            {value === 1 && (
              <span style={{ color: '#FFC300', fontWeight: '700' }}>
                PENDENTE
              </span>
            )}
            {value === 2 && (
              <span style={{ color: '#008000', fontWeight: '700' }}>
                APROVADO
              </span>
            )}
            {value === 0 && (
              <span style={{ color: '#C70039', fontWeight: '700' }}>
                REPROVADO
              </span>
            )}
          </div>
        ),
      },
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <ButtonAction to={`provider/${value}`}>
            <IoIosPerson style={{ marginRight: 10 }} /> Visualizar perfil
          </ButtonAction>
        ),
      },
    },
  ];

  const options = {
    filter: false,
    searchable: true,
    selectableRows: false,
    rowsPerPage: 15,
    elevation: 2,
    textLabels: {
      body: {
        noMatch: 'Nenhum profissional foi encontrado',
        toolTip: 'Organizar',
        columnHeaderTooltip: column => `Organizar por ${column.label}`,
      },
      pagination: {
        next: 'Próxima página',
        previous: 'Página anterior',
        rowsPerPage: 'Registros por página:',
        displayRows: 'de',
      },
      toolbar: {
        search: 'Buscar',
        downloadCsv: 'Download CSV',
        print: 'Imprimir',
        viewColumns: 'Visualizar colunas',
        filterTable: 'Filtrar tabela',
      },
      filter: {
        all: 'Todos',
        title: 'FILTROS',
        reset: 'RESETAR',
      },
      viewColumns: {
        title: 'Mostrar colunas na tabela',
        titleAria: 'Mostrar/Esconder colunas',
      },
      selectedRows: {
        text: 'linha(s) selecionadas',
        delete: 'Deletar',
        deleteAria: 'Deletar registros selecionados',
      },
    },
  };
  return (
    <Container>
      <HeaderTab>
        <ButtonActionTabs className="mr-2" active>
          <MdAccountBox className="mr-05" /> Lista de profissionais
        </ButtonActionTabs>
        <ButtonActionTabs to="/partners" className="mr-2">
          <MdPeopleOutline className="mr-05" /> Lista de parceiros
        </ButtonActionTabs>
        <ButtonActionTabs to="/services">
          <MdPeopleOutline className="mr-05" /> Lista de serviços
        </ButtonActionTabs>
      </HeaderTab>
      {/* <Title>
        <MdAccountBox className="mr-05" /> Lista de profissionais
      </Title> */}

      <div className="mt-3 mb-3">
        <hr />
        <p>Filtrar por: {nameFilter}</p>
        <button
          type="button"
          className="btn btn-sm btn-warning mr-2"
          onClick={() => handleFilter(1)}
        >
          Pendentes
        </button>
        <button
          type="button"
          className="btn btn-sm btn-success mr-2"
          onClick={() => handleFilter(2)}
        >
          Aprovados
        </button>
        <button
          type="button"
          className="btn btn-sm btn-danger mr-2"
          onClick={() => handleFilter(0)}
        >
          Reprovados
        </button>
        <button
          type="button"
          className="btn btn-sm btn-primary mr-2"
          onClick={() => handleFilter(3)}
        >
          Limpar filtros
        </button>
      </div>

      {loading ? (
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ReactLoading type="bubbles" color="#6c63ff" height={40} width={40} />
        </div>
      ) : (
        providers !== null && (
          <MUIDataTable
            title="Profissionais"
            data={showFilter ? providersFilter : providers}
            columns={columns}
            options={options}
          />
        )
      )}
    </Container>
  );
}

/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { IoIosPerson } from 'react-icons/io';

import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import pt from 'date-fns/locale/pt';

// ReactLoading
import ReactLoading from 'react-loading';

// icons
import { MdAccountBox } from 'react-icons/md';

// api
import api from '../../services/api';

// styles
import {
  Container,
  Title,
  CardMain,
  AvatarContainer,
  ButtonNoAction,
} from './styles';

export default function ProvidersDetails({ match }) {
  const [loading, setLoading] = useState(true);
  const [provider, setProvider] = useState(null);

  const { id } = match.params;

  async function getProvider() {
    try {
      const responseProvider = await api.get(`admin/providers/show/${id}`);
      setProvider(responseProvider.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error('Erro ao listar provedor');
    }
  }

  async function handleStatus(status) {
    try {
      setLoading(true);
      const responseStatus = await api.put('admin/providers/update', {
        id,
        status,
      });
      setProvider(responseStatus.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error('Erro ao atualizar status');
    }
  }

  useEffect(() => {
    getProvider();
  }, [getProvider]);

  return (
    <Container>
      <Title>
        <MdAccountBox className="mr-05" /> {provider !== null && provider.name}
      </Title>

      {loading ? (
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ReactLoading type="bubbles" color="#6c63ff" height={40} width={40} />
        </div>
      ) : (
        provider !== null && (
          <>
            <CardMain>
              <header>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    marginRight: 30,
                  }}
                >
                  <h3>{provider.name}</h3>
                  <p className="nickname">
                    Apelido:{' '}
                    {provider.nickname === null ? 'nenhum' : provider.nickname}
                  </p>
                  <AvatarContainer>
                    {provider.url_photo !== null && (
                      <img src={provider.url_photo} alt="" />
                    )}
                  </AvatarContainer>
                </div>
                <div>
                  <p className="description">
                    Serviço(s):
                    {provider.services.map(service => (
                      <span className="description">
                        {' '}
                        {service.name}
                        {', '}
                      </span>
                    ))}
                  </p>
                  <hr />
                  <p className="description">E-mail: {provider.email}</p>
                  <hr />
                  <p className="description">CPF: {provider.cpf}</p>
                  <hr />
                  <p className="description">Telefone: {provider.phone}</p>
                  <hr />
                  <p className="description">
                    Data do registro:{' '}
                    {format(new Date(provider.created_at), 'dd/MM/yyyy - H:ii')}
                  </p>
                  <hr />
                  <p className="description">
                    Assinatura:{' '}
                    {provider.subscription === null
                      ? 'Sem assinatura do plano'
                      : `ID pagarme: #${provider.subscription}`}
                  </p>
                  <hr />
                  <p className="description">
                    Status:{' '}
                    {provider.status === 1 && (
                      <span style={{ color: '#FFC300', fontWeight: '700' }}>
                        PENDENTE
                      </span>
                    )}
                    {provider.status === 2 && (
                      <span style={{ color: '#008000', fontWeight: '700' }}>
                        APROVADO
                      </span>
                    )}
                    {provider.status === 0 && (
                      <span style={{ color: '#C70039', fontWeight: '700' }}>
                        REPROVADO
                      </span>
                    )}
                  </p>
                  <hr />
                  {provider.status === 1 && (
                    <div style={{ display: 'flex' }}>
                      <ButtonNoAction
                        type="button"
                        onClick={() => handleStatus(2)}
                        className="mr-2"
                        bgColor="#008000"
                      >
                        Aprovar provedor
                      </ButtonNoAction>
                      <ButtonNoAction
                        type="button"
                        onClick={() => handleStatus(0)}
                        bgColor="#C70039"
                      >
                        Reprovar provedor
                      </ButtonNoAction>
                    </div>
                  )}
                  {provider.status === 2 && (
                    <div style={{ display: 'flex' }}>
                      <ButtonNoAction
                        type="button"
                        onClick={() => handleStatus(1)}
                        className="mr-2"
                        bgColor="#FFC300"
                      >
                        Voltar para pendente
                      </ButtonNoAction>
                      <ButtonNoAction
                        type="button"
                        onClick={() => handleStatus(0)}
                        bgColor="#C70039"
                      >
                        Reprovar provedor
                      </ButtonNoAction>
                    </div>
                  )}
                  {provider.status === 0 && (
                    <div style={{ display: 'flex' }}>
                      <ButtonNoAction
                        type="button"
                        onClick={() => handleStatus(1)}
                        className="mr-2"
                        bgColor="#FFC300"
                      >
                        Voltar para pendente
                      </ButtonNoAction>
                      <ButtonNoAction
                        type="button"
                        onClick={() => handleStatus(2)}
                        className="mr-2"
                        bgColor="#008000"
                      >
                        Aprovar provedor
                      </ButtonNoAction>
                    </div>
                  )}
                </div>
              </header>
            </CardMain>

            <Title>Documento</Title>

            <CardMain>
              {provider.files.map(file => (
                <div style={{ display: 'flex' }}>
                  <a href={file.url} target="_blabl">
                    <img src={file.url} alt="" width="200" />
                  </a>
                </div>
              ))}
            </CardMain>
          </>
        )
      )}
    </Container>
  );
}

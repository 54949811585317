/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Form, Input } from '@rocketseat/unform';

// ReactLoading
import ReactLoading from 'react-loading';

// icons
import { MdAccountBox, MdRefresh, MdGroupAdd } from 'react-icons/md';

// api
import api from '../../services/api';

import history from '../../services/history';

// styles
import { Container, Title, CardMain, FormGroup } from './styles';

export default function ServiceCreate() {
  const [loading, setLoading] = useState(false);
  const [dataImage, setDataImage] = useState(null);

  // getImage
  function handleChangeImage(e) {
    const file = e.target.files[0];
    setDataImage(file);
  }

  async function handleCreate(data) {
    if (dataImage === null) {
      return toast.error('Seleciona uma imagem para seu serviço');
    }
    if (data.name === '') {
      return toast.error('O campo Nome é obrigatório');
    }

    try {
      setLoading(true);
      const dataForm = new FormData();
      dataForm.append('file', dataImage);
      dataForm.append('name', data.name);
      dataForm.append('description', data.description);
      await api.post('services/create', dataForm);
      toast.success('Serviço registrado com sucesso!');
      return history.push('/services');
    } catch (error) {
      setLoading(false);
      return toast.error('Erro ao cadastrar serviço');
    }
  }

  return (
    <Container>
      {/* <HeaderTab>
        <ButtonActionTabs className="mr-2" to="/dashboard">
          <MdAccountBox className="mr-05" /> Lista de provedores
        </ButtonActionTabs>
        <ButtonActionTabs active>
          <MdPeopleOutline className="mr-05" /> Lista de parceiros
        </ButtonActionTabs>
      </HeaderTab> */}
      <Title>
        <MdGroupAdd className="mr-05" /> Adicionar novo serviço
      </Title>

      <CardMain>
        <Form onSubmit={handleCreate}>
          <FormGroup>
            <Input
              name="file"
              type="file"
              accept="image/*"
              id="logo"
              onChange={handleChangeImage}
              placeholder="Imagem do serviço"
              required
            />
            <Input name="name" placeholder="Nome do serviço" required />
            <Input name="description" placeholder="Descrição" />
          </FormGroup>

          <button className="profileUpdate" type="submit">
            <MdGroupAdd size={20} className="mr-1" /> Criar serviço
          </button>
        </Form>
      </CardMain>
    </Container>
  );
}

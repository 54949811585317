/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Form, Input } from '@rocketseat/unform';

// ReactLoading
import ReactLoading from 'react-loading';

// icons
import { MdAccountBox, MdRefresh, MdGroupAdd } from 'react-icons/md';

// api
import api from '../../services/api';

import history from '../../services/history';

// styles
import { Container, Title, CardMain, FormGroup } from './styles';

export default function PartnersCreate() {
  const [loading, setLoading] = useState(false);
  const [dataImage, setDataImage] = useState(null);

  // getImage
  function handleChangeImage(e) {
    const file = e.target.files[0];
    setDataImage(file);
  }

  async function handleCreate(data) {
    if (dataImage === null) {
      return toast.error('Seleciona uma imagem para seu parceiro');
    }
    if (data.name === '') {
      return toast.error('O campo Nome é obrigatório');
    }

    try {
      setLoading(true);
      const dataForm = new FormData();
      dataForm.append('file', dataImage);
      dataForm.append('name', data.name);
      dataForm.append('category', data.category);
      dataForm.append('facebook', data.facebook);
      dataForm.append('instagram', data.instagram);
      dataForm.append('twitter', data.twitter);
      dataForm.append('description', data.description);
      await api.post('partners/create', dataForm);
      toast.success('Parceiro registrado com sucesso!');
      return history.push('/partners');
    } catch (error) {
      setLoading(false);
      return toast.error('Erro ao cadastrar parceiro');
    }
  }

  return (
    <Container>
      {/* <HeaderTab>
        <ButtonActionTabs className="mr-2" to="/dashboard">
          <MdAccountBox className="mr-05" /> Lista de provedores
        </ButtonActionTabs>
        <ButtonActionTabs active>
          <MdPeopleOutline className="mr-05" /> Lista de parceiros
        </ButtonActionTabs>
      </HeaderTab> */}
      <Title>
        <MdGroupAdd className="mr-05" /> Adicionar novo parceiro
      </Title>

      <CardMain>
        <Form onSubmit={handleCreate}>
          <FormGroup>
            <Input
              name="file"
              type="file"
              accept="image/*"
              id="logo"
              onChange={handleChangeImage}
              placeholder="Logomarca do parceiro"
              required
            />
            <Input name="name" placeholder="Nome do perceiro" required />
            <Input name="category" placeholder="Categoria" />
            <Input name="site" placeholder="Site" />
            <Input name="facebook" placeholder="Facebook" />
            <Input name="instagram" placeholder="Instagram" />
            <Input name="twitter" placeholder="Twitter" />
            <Input name="description" placeholder="Descrição" />
          </FormGroup>

          <button className="profileUpdate" type="submit">
            <MdGroupAdd size={20} className="mr-1" /> Criar parceiro
          </button>
        </Form>
      </CardMain>
    </Container>
  );
}

/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Form, Input } from '@rocketseat/unform';

// ReactLoading
import ReactLoading from 'react-loading';

// icons
import { MdAccountBox, MdRefresh } from 'react-icons/md';

// api
import api from '../../services/api';

import history from '../../services/history';

// styles
import {
  Container,
  Title,
  CardMain,
  FormGroup,
  ContainerImage,
} from './styles';

export default function PartnersEdit({ match }) {
  const [loading, setLoading] = useState(true);
  const [loadingImage, setLoadingImage] = useState(false);
  const [partner, setPartner] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  const { id } = match.params;

  async function getPartner() {
    try {
      const responsePartner = await api.get(`partners/show/${id}`);
      setPartner(responsePartner.data);
      setPreviewImage(responsePartner.data.url_photo);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error('Erro ao listar parceiro');
    }
  }

  // upload new image
  async function handleChange(e) {
    setLoadingImage(true);
    const file = e.target.files[0];
    const data = new FormData();
    data.append('file', file);
    try {
      const responseImage = await api.put(`partners/update/foto/${id}`, data);
      setPreviewImage(responseImage.data.url_photo);
      setLoadingImage(false);
    } catch (error) {
      setLoadingImage(false);
      toast.error('Erro ao atualizar imagem de parceiro');
    }
  }

  // update partner values
  async function handleUpdate(data) {
    if (data.name === '') {
      return toast.error('O campo Nome é obrigatório');
    }
    try {
      setLoading(true);
      const responseUpdate = await api.put(`partners/update/${id}`, data);
      setPartner(responseUpdate.data);
      setLoading(false);
      toast.success('Dados atualizados com sucesso!');
      return history.push('/partners');
    } catch (error) {
      setLoading(false);
      return toast.error('Erro ao atualizar parceiro');
    }
  }

  useEffect(() => {
    getPartner();
  }, [getPartner]);

  return (
    <Container>
      {/* <HeaderTab>
        <ButtonActionTabs className="mr-2" to="/dashboard">
          <MdAccountBox className="mr-05" /> Lista de provedores
        </ButtonActionTabs>
        <ButtonActionTabs active>
          <MdPeopleOutline className="mr-05" /> Lista de parceiros
        </ButtonActionTabs>
      </HeaderTab> */}
      <Title>
        <MdAccountBox className="mr-05" /> {partner !== null && partner.name}
      </Title>

      {loading ? (
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ReactLoading type="bubbles" color="#6c63ff" height={40} width={40} />
        </div>
      ) : (
        partner !== null && (
          <CardMain>
            <Form initialData={partner} onSubmit={handleUpdate}>
              <FormGroup>
                <ContainerImage>
                  {loadingImage ? (
                    <ReactLoading
                      type="bubbles"
                      color="#6c63ff"
                      height={40}
                      width={40}
                    />
                  ) : (
                    <label htmlFor="logo">
                      <img src={previewImage} alt="Logo" />
                      <input
                        type="file"
                        accept="image/*"
                        id="logo"
                        onChange={handleChange}
                      />
                    </label>
                  )}
                </ContainerImage>
              </FormGroup>
              <FormGroup>
                <Input name="name" placeholder="Nome do perceiro" required />
                <Input name="category" placeholder="Categoria" />
                <Input name="site" placeholder="Site" />
                <Input name="facebook" placeholder="Facebook" />
                <Input name="instagram" placeholder="Instagram" />
                <Input name="twitter" placeholder="Twitter" />
                <Input name="description" placeholder="Descrição" />
              </FormGroup>

              <button className="profileUpdate" type="submit">
                <MdRefresh size={20} className="mr-1" /> Atualizar valores
              </button>
            </Form>
          </CardMain>
        )
      )}
    </Container>
  );
}
